
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>


   <!-- App Wrapper -->
    <div class="app-wrapper">

        <!-- Top Navigation -->
        <div class="top-nav">
            <div class="nav-left">Referrals</div>
            <div class="nav-right">
                <!-- <div class="location-dropdown">{{locationName}} <span>&#9662;</span></div> -->
            </div>
        </div>

        <!-- Content Section -->
        <div class="content">
            <h2>Make your happy customers bring their local friends to</h2>
            <p>Set up a simple local referral program and grow your customers with a click of a button</p>

            <ul class="steps-list">
                <li><img src="../../../../assets/images/step1.svg" alt="Set up Reward"> Set up Reward value for referrer</li>
                <li><img src="../../../../assets/images/step2.svg" alt="Set up Reward Referee"> Set up reward value for Referee</li>
                <li><img src="../../../../assets/images/step3.svg" alt="Upload Banner"> Upload Social media banner and message</li>
                <li><img src="../../../../assets/images/step4.svg" alt="Launch Program"> Launch referral program</li>
            </ul>

            <p>Referrar: A person who refers their friends</p>
            <p>Referee: A person who received the referral invitation</p>

            <p class="how-it-works"><a href="#" id="how-it-works-link">How it works?</a></p>

            <button class="cta-button" (click)="open()">Continue set up</button>
        </div>
 
    </div>
 <div class="trail-model" *ngIf="openModel">
         
              <div (click)="close()">
              <img src="../../../../assets/images/cancel.png" class="close3">
            </div>
<p>Please use the mobile app to set up referrals
</p>
<a class="cursor" href="https://play.google.com/store/apps/details?id=com.localfirst.merchantapp" target="_blank">Open the app</a>


        
       </div>
         
    <!-- Modal for How to Set Up Referrals -->
    <div id="setup-referrals-modal" class="modal">
        <div class="modal-content">
            <h3>How to Set up Referrals</h3>
            <p>You have to use our mobile app LocalFirst to set up referrals</p>
            <ol>
                <li>Open LocalFirst app</li>
                <li>Click on Referrals tab at the bottom</li>
                <li>Set up Referral program</li>
                <li>Launch it, and send Referrals requests</li>
                <li>Grow local business with more word of mouth</li>
            </ol>
            <div class="modal-buttons">
                <button class="close-button" id="close-modal-btn">Close</button>
                <button class="open-app-button" id="open-app-btn">Open the app</button>
            </div>
        </div>
    </div>

    