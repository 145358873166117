import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refferalsmoblie',
  templateUrl: './refferalsmoblie.component.html',
  styleUrls: ['./refferalsmoblie.component.css'],
})
export class RefferalsmoblieComponent implements OnInit {
  locationName: any;
  openModel: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.locationName = localStorage.getItem('locationName');
  }
  open() {
    this.openModel = true;
  }
  close() {
    this.openModel = false;
  }
}
