<app-navbar></app-navbar>
<div class="main">

  <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
                           <h5>Easiest way to grow Instagram followers for local businesses
</h5>

              <br>
              <h4>Give it a try for free with 14-days free trial</h4>
              <br>
              <div class="d-flex">
                 <!-- <div class="form_Section">
                    <input placeholder="Enter e-mail">
                 </div> -->
                 <div class="submit_section">
                      <a class="cursor" href="https://play.google.com/store/apps/details?id=com.localfirst.merchantapp" target="_blank">Download the app</a>
                 </div>

              </div>
              <h4 class="mt-5">"This software app is a blessing for local businesses"</h4>
              

          </div>
            </div>
            <div class="col-md-6 type_img">
               <img src="../../../assets/images/Group 1000002829.png">
            </div>
        </div>
         
      </div>
    
 <!-- <div class="push_button_section">
      <div class="container pt-md-5">
        <div class="row">
            <div class="col-md-6">
          <div class="button_push_section">
              <h5>Make repeat customers talk about your local business with <span>Friends & Family</span>
            </h5>
       
              <div class="d-flex">
                 
                 <div class="submit_section">
                    <button class="cursor" disabled>Launching soon</button>
                 </div>
              </div>
          </div>
            </div>
         <div class="col-md-6 type_img">
               <img src="../../../assets/images/referal.jpg">
            </div>
        </div>
         
      </div>
   </div>




 
     
   

   <div class=" pt-md-5">
      <div class=" beat_your_competetion container"  >

        
        <h4>The simplest and easiest referral system for local business</h4>
       
        </div>
         <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/ref1.svg">
                </div>
                <div class="col-md-6 mt-md-4">
                  
                    <h5>Request for a Referral</h5>
                    <ul>
                      <li>Launch referrals with one click </li>
                      <li>Ask repeat customers to refer their friends and family </li>
                      <li>Encourage them with rewards </li>
                      <li>Acquire new customers from existing happy customers </li>
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
              </div>
           </div>
           <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6 mt-md-4">
                    
                    <h5>App Sends SMS with Referral Link</h5>
                    <ul>
                      <li>Happy customers gets a simple SMS at end of the transaction </li>
                      <li>With one click they can refer their friends </li>
                      <li>Smooth referral system to get rewarded </li>
                     
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
                <div class="col-md-6 ">
                  <img src="../../../assets/images/ref2.svg">

                
                </div>
              </div>
           </div>
             <div class="review_section container mt-md-5">
              <div class="row ">
                <div class="col-md-6">
                  <img src="../../../assets/images/ref3.svg">
                </div>
                <div class="col-md-6 mt-md-4">
            
                    <h5>Track Referrals Activity</h5>
                    <ul>
                      <li>View and manage referrals </li>
                      <li>Monitor new customer visits via referrals </li>
                      <li>See more customers and more revenue in your dashboard </li>
                    
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
              </div>
           </div>
         <div class="review_section container mt-md-5">
              <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6">
               
                    <h5>Track Referral Revenue</h5>
                    <ul>
                      <li>See how much revenue you made from referrals </li>
                      <li>Monitor business growth with referrals </li>
                      <li>Just grow your revenue with few clicks</li>
                      
                    </ul>
                    <div class="started_button">
                      <button class="get ml-3">Launching soon</button>
                     
                    </div>
                </div>
                <div class="col-md-6 mt-md-4">
                  
                  <img src="../../../assets/images/ref4.svg">

                </div>
              </div>
           </div>
         
          
         
         
  
        
   
        
            
  <div class=" pt-md-5">
      <div class="people_section_problem container">

        <h4>Turn repeat customers into a word-of-mouth marketing for your local business</h4>
         
         
      </div>
   </div> 
           
      
        </div>
</div> -->
<app-footer></app-footer>