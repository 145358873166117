
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>

    <!-- App Wrapper -->
    <div class="app-wrapper">
        
        <!-- Top Navigation -->
        <div class="top-nav">
            <div class="nav-left">Reviews</div>
            <div class="nav-right">
                 <div class="location-dropdown" id="dropdown-button" >
                <!-- <span id="location-name" class="truncate">{{locationName}}</span>
               <img src="../../../../assets/images/arrow-down.svg" alt="down-arrow">
                <ul id="location-list" class="dropdown hidden"></ul> -->

                <select (change)="locationChage($event.target)">
                    <option>{{locationName}}</option>
                    <option *ngFor="let loc of LocationDetails" [value]="loc._id" >{{loc.name}}</option>
                </select>
            </div>
            </div>
        </div>

        <!-- Review Filters -->
        <div class="filters">
               <select class="filter-dropdown" (change)="changeFilter($event)">
                <option value="thisWeek">This week</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This year</option>
            </select>
            <select class="filter-dropdown">
                <option>Google</option>
                <!-- <option>Facebook</option> -->
            </select>
            <button class="help-button">Help</button>
        </div>

        <!-- Review Stats -->
        <div class="review-stats">
            <div class="stat-card blue">
                <h2>{{rating}}</h2>
                <div class="star-rating">
  <span class="star" [ngClass]="{'filled': rating >= 1, 'half-filled': rating >= 0.5 && rating < 1}">★</span>
  <span class="star" [ngClass]="{'filled': rating >= 2, 'half-filled': rating >= 1.5 && rating < 2}">★</span>
  <span class="star" [ngClass]="{'filled': rating >= 3, 'half-filled': rating >= 2.5 && rating < 3}">★</span>
  <span class="star" [ngClass]="{'filled': rating >= 4, 'half-filled': rating >= 3.5 && rating < 4}">★</span>
  <span class="star" [ngClass]="{'filled': rating >= 5, 'half-filled': rating >= 4.3 && rating < 5}">★</span>
</div>

                 <!-- <star-rating [starType]="'svg'" [rating]="rating"  size="small" space="no"
             ></star-rating> -->
                <p class="mb-3">Overall Google Ratings</p>
                <span class="star1">Always aim for 5 star rating</span>
            </div>
            <div class="stat-card orange">
                <h2>{{reviewCount}}</h2>
                <p class="mt-4">Total Google Reviews</p>
                <span class="star2">Keep growing your reviews</span>
            </div>
        </div>

          <div class="card activity">
                      <div class="">
                    <h2>Reviews Activity <span class="orange"></span></h2>
                    <p>You sent <a href="#">{{reviewDataCount?.requestSent}} review requests</a> in the {{dateFliter.trim(',')}}.</p>
                    <!-- <p class="prompt">Send your first review request</p> -->
                </div>
                   <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="reviewschart" 
             style="width:300px; height: 300px;display:block"></highcharts-chart>
                    <div class="activity-grid pt-3">
                        <div class="border1">
                            <div class="d-flex">
                                <div>
                            <img src="../../../../assets/images/send.svg" alt="send Icon">

                                </div>
                                <div>
                                           <span class="count">{{reviewDataCount?.requestSent}}</span>
                            <span class="label">Request sent</span>
                                </div>
                            </div>
                     
                        </div>
                        <div>
                            <img src="../../../../assets/images/clicks.svg" alt="send Icon">
                            <span class="count">{{reviewDataCount?.clicked}}</span>
                            <span class="label">Clicks</span>
                        </div>
                      
                      
                    </div>
                </div>
 <!-- Sample Reviews Section -->
        <div class="reviews-section" *ngIf="reviews !=''">
            <h2>All reviews</h2>
            <h3>Some of your reviews are shown here....</h3>

            <div class="review-item" *ngFor="let r of reviews">
                <div class="d-flex justify-content-between reviews1">
                    <div class="d-flex">
                        <div>
                        <!-- <img src="{{r.authorAttribution.photoUri}}"> -->
                        </div>
                        <div>
                          <h6>{{r.authorAttribution.displayName}}</h6>
                                  <div class="star-rating">
  <span class="star" [ngClass]="{'filled': r.rating >= 1, 'half-filled': r.rating >= 0.5 && r.rating < 1}">★</span>
  <span class="star" [ngClass]="{'filled': r.rating >= 2, 'half-filled': r.rating >= 1.5 && r.rating < 2}">★</span>
  <span class="star" [ngClass]="{'filled': r.rating >= 3, 'half-filled': r.rating >= 2.5 && r.rating < 3}">★</span>
  <span class="star" [ngClass]="{'filled': r.rating >= 4, 'half-filled': r.rating >= 3.5 && r.rating < 4}">★</span>
  <span class="star" [ngClass]="{'filled': r.rating >= 5, 'half-filled': r.rating >= 4.5 && r.rating < 5}">★</span>
</div>
<span class="ml-3">{{r.rating}}.0</span>
                        </div>
                    </div>
                    <div>
                        <p>{{r.relativePublishTimeDescription}}</p>
                    </div>

                </div>
             
                    <p class="textr">{{r.originalText.text}}</p>
                
            </div>

             
        

    </div>
        <!-- Review Requests Section -->
        <div class="review-requests" *ngIf="reviews == ''">
            <h3>Reviews Requests</h3>
            <p>You did not send review requests yet. Click on Request Review button to get started.</p>
        </div>

             <!-- Floating Request Review Button -->
       <!-- Floating Request Review Button in home.html -->
<button class="request-btn" (click)="redirectToRequestReview()"> 
    <img src="../../../../assets/images/magic-star.png" alt="Star Icon" class="button-icon">
    Request Review
</button>

</div>