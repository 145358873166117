<app-navbar></app-navbar>

<div class="main">
    <div class="container">
        <h4>Contact Us</h4>



        <div class="contact">
          <h6>Should you have a question? Please email us
</h6>
<p>
support&#64;localfirst.co.in</p>

<h5>
  Our  Corporate Office Address

</h5>
<h4>91SpringBoard Building, 2nd Floor, 41/11/6/2, opposite to Sharath City Mall, Hanuman Nagar, Prashanth Nagar Colony, Kondapur, Hyderabad, Telangana 500084</h4>
        </div>
        <!-- <div>
              <div class="L-input">
                <input type="text" formControlName="Delivered" class="form-control" placeholder="Enter your name" >
            </div>
              <div class="L-input">
                <input type="text" formControlName="Delivered" class="form-control" placeholder="Enter your email address" >
            </div>
                <div class="L-input">
                <select  class="form-control" placeholder="Why do you want to contact us" formControlName="reviewName">
                  <option value="" selected hidden disabled>Please select</option>
                </select>

            </div>
              <div class="L-input">
                <p>Describe any details if you would us to understand more about your enquiry</p>
                <textarea type="text" height="120px" formControlName="review"></textarea>

            </div>
            <div class="submit">
            <button class="cursor">Submit</button>
        </div>
        </div> -->
    </div>
</div>

<!-- <app-footer></app-footer> -->