import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-moblienavbar',
  templateUrl: './moblienavbar.component.html',
  styleUrls: ['./moblienavbar.component.css'],
})
export class MoblienavbarComponent implements OnInit {
  routeParam: any;
  routeNavigate: any;

  constructor(private router: Router, private activateRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeParam = window.location.pathname.replace('/', '');
    this.routeNavigate = this.routeParam;
  }
  route(route: any) {
    this.router.navigate([`${route}`]);
    this.routeNavigate = route;
  }
}
