import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-reviewsmoblie',
  templateUrl: './reviewsmoblie.component.html',
  styleUrls: ['./reviewsmoblie.component.css'],
})
export class ReviewsmoblieComponent implements OnInit {
  locationName: any;
  location_id: any | null;
  rating: any;
  reviewCount: any;
  highcharts = Highcharts;
  refferalchart: any;
  customerVisitChart: any;
  reviewschart: any;
  reviewsData: any = ['RequestSent', 'Clicks'];
  reviewDataCount: any;
  dateFliter: any = 'thisWeek';
  reviews: any;
  locationsDetailsComplete: any;
  LocationDetails: any;
  constructor(private service: ServicesService, private router: Router) {}

  ngOnInit(): void {
    this.apiCall();
  }
  redirectToRequestReview() {
    this.router.navigate(['/Reviews-request']);
  }

  locationChage(e: any) {
    console.log(e.value);

    this.locationsDetailsComplete = this.LocationDetails.filter((res: any) => {
      return res._id == e.value;
    });
    console.log(this.locationsDetailsComplete);
    this.locationName = localStorage.setItem(
      'locationName',
      this.locationsDetailsComplete[0].name
    );
    this.location_id = localStorage.setItem(
      'locationId',
      this.locationsDetailsComplete[0]._id
    );
    this.apiCall();
  }
  changeFilter(e: any) {
    this.dateFliter = e.target.value;
    this.apiCall();
  }
  apiCall() {
    this.locationName = localStorage.getItem('locationName');
    this.location_id = localStorage.getItem('locationId');
    this.service.merchantDetails().subscribe((result: any) => {
      console.log(result);
      this.LocationDetails = result.logInData.locations;
      this.locationsDetailsComplete = this.LocationDetails.filter(
        (res: any) => {
          return res._id == this.location_id;
        }
      );
      console.log(this.locationsDetailsComplete);

      this.rating = this.locationsDetailsComplete[0].rating;
      this.reviews = this.locationsDetailsComplete[0].reviews;
      this.reviewCount = this.locationsDetailsComplete[0].user_ratings_total;
    });
    let obj = {
      locationId: this.location_id,
      duration: this.dateFliter,
    };
    this.service.reviewsData(obj).subscribe((result) => {
      // this.service.reviewsDashboard(obj).subscribe((result) => {
      console.log(result);
      // this.referralActivityCount = result.referralActivity;
      this.reviewDataCount = result.reviewActivity;
      // this.customerVisitCount = result.customerVisit;
      // console.log(this.referralActivityCount);
      this.reviewschart = {
        chart: {
          type: 'column',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: 'Reviews',
        },
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        autoRotation: false,
        xAxis: {
          type: 'categories',
          categories: this.reviewsData,
        },

        legend: {
          enabled: false,
          reversed: false,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.2, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y}',
            },
          },
        },
        colors: ['#EBBD95'],

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },

        series: [
          {
            // name: this.data,
            // data: this.count,
            data: [
              {
                name: 'requestSent',
                y: this.reviewDataCount.requestSent,
                drilldown: 'requestSent',
              },
              {
                name: 'clicked',
                y: this.reviewDataCount.clicked,
                drilldown: 'clicked',
              },
            ],
          },
        ],
      };
    });
  }
}
