
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>
<app-loader *ngIf="login1Loader"></app-loader>

  <div class="container" *ngIf="planA">
        <div class="header">
              <div class="location-dropdown" id="dropdown-button" >
                <!-- <span id="location-name" class="truncate">{{locationName}}</span>
               <img src="../../../../assets/images/arrow-down.svg" alt="down-arrow">
                <ul id="location-list" class="dropdown hidden"></ul> -->

                <select (change)="locationChage($event.target)">
                    <option>{{locationName}}</option>
                    <option *ngFor="let loc of LocationDetails" [value]="loc._id" >{{loc.name}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="tokenDetailsTrail == 'active'">
              <p class="trail">Your free trial for <span class="location-name">{{locationName}}</span> will expire in {{daysBetween}} <span class="days">Days</span></p>
        </div>
        <div *ngIf="tokenDetailsTrail == 'expired' && tokenDetails=='inactive'">
              <p class="trail e">Your free trial has expired.Upgrade to premium</p>
                 <p class="alert-message">
            <span>ALERT:</span> You won’t be able to request for new Google reviews and Referrals via our app. 
            Please upgrade to premium subscription to continue using our software.
        </p>
        </div>
      
        <div *ngIf="tokenDetails == 'active'">
                     <p class="subscription-status mb-4" >Your subscription active and will expire in <span class="days-left">{{subdaysBetween}}-days</span></p>

        </div>
        <div *ngIf="tokenDetails == 'expired'">
              <p class="expired-message">Your subscription for <span class="location-name">{{locationName}}</span> has expired</p>
        <p class="alert-message">
            <span>ALERT:</span> You won’t be able to request for new Google reviews and Referrals via our app. 
            Please upgrade to premium subscription to continue using our software.
        </p>
        </div>
        <div *ngIf="tokenDetails == 'cancelled'">
               <p class="expired-message">Your subscription for <span class="location-name">{{locationName}}</span> has cancelled</p>
        <p class="alert-message">
            <span>ALERT:</span> You won’t be able to request for new Google reviews and Referrals via our app. 
            Please upgrade to premium subscription to continue using our software.
        </p>
        </div>
        <!-- <h2 class="trail1">Upgrade to Premium before it expires</h2> -->
        <div class="plan-card">
            <h3>Premium Plan</h3>
            <p class="price"><i class="fa fa-inr" aria-hidden="true"></i>

{{SubscriptionPlan?.amount - SubscriptionPlan?.gst}}/monthly</p>
            <h4>Premium Plan Benefits</h4>

            <div class="d-flex justify-content-between" *ngFor="let plan of freeTrail">
                <div class="name">
{{ plan.name}}
                </div>
                <div class="count">
{{plan.count}}
                </div>
            </div>
      
            <button class="btn-upgrade cursor" *ngIf="tokenDetailsTrail == 'active'" (click)="upgrade(SubscriptionPlan?.per_day_price)">Upgrade to Premium</button>
            <button class="btn-upgrade cursor" *ngIf="tokenDetailsTrail == 'expired' && tokenDetails=='inactive'" (click)="upgrade(SubscriptionPlan?.per_day_price)">Upgrade to Premium</button>
            <button class="btn-upgrade cursor" *ngIf="tokenDetails == 'active'" (click)="upgrade(SubscriptionPlan?.per_day_price)">Renew now</button>
            <button class="btn-upgrade cursor" *ngIf="tokenDetails == 'inactive' && tokenDetailsTrail=='inactive'" (click)="upgrade(SubscriptionPlan?.per_day_price)">Renew now</button>
            <button class="btn-upgrade cursor" *ngIf="tokenDetails == 'expired' || tokenDetails == 'cancelled'" (click)="upgrade(SubscriptionPlan?.per_day_price)">Reactivate the plan</button>
            <a  class="view-plans cursor" (click)="viewPlans()">View Other Plans</a>
        </div>
        <p class="check">If you want to check your usage, <a href="#">Open the app</a></p>
        <div class="help-card">
            <p>Need help?</p>
            <a href="#">Contact us</a>
        </div>
        <br>
    </div> 

    <div class="container mt-4" *ngIf="expiredScreen2">
        <p class="select-message">Please select a subscription for <span class="location-name">{{locationName}}</span> to continue</p>
        <div  *ngFor="let plan of SubscriptionPlanDetails.reverse()" >
     <div class="plan-card mb-3 cursor" [ngClass]="{'active-card': plan.isActive}" (click)="toggleCard(plan)">
            <h3>{{plan.type}}</h3>
            <p class="price"><i class="fa fa-inr" aria-hidden="true"></i>
{{(plan.amount) - (plan.gst)}} <span *ngIf="plan.type != 'Monthly'" class="actual_price">{{plan.actual_price}}</span></p>
            <p class="description">You're spending <i class="fa fa-inr" aria-hidden="true"></i>
{{plan.final_discounted_monthly_price}} per month only to grow online reputation and to get more local referrals and manage customer contacts for </p>
            
        </div>
        
        <div *ngIf="plan.type =='Monthly'" class="recommand">Recommended for your business</div>
        </div>
   
        <p class="error">{{selectAnyPlan}}</p>
        
        
        <button class="btn-continue" (click)="continue()">Continue</button>
        
        <div class="help">
            <a class="need-help">Need Help</a>
        </div>
        
    </div>

     <div class="container" *ngIf="Subscription">
        <div class="trail-model" *ngFor="let p of planDetails">
            <div >
              <img src="../../../../assets/images/cancel.png" (click)="close()" class="close3">
            </div>
        <div class="header">
            <div class="indicator"></div>
        </div>
        <h2>{{p.type}} Subscription plan</h2>
        <div class="order-summary">
            <h3>Order summary</h3>
            <p>You have selected {{p.type}} subscription type for <span class="location">{{locationName}}</span></p>
            <div class="summary-details">
                <div class="detail-row">
                    <span>Subscription type</span>
                    <span class="black">{{p.type}}</span>
                </div>
                <div class="detail-row">
                    <span>Price</span>
                    <span><i class="fa fa-inr" aria-hidden="true"></i>

{{p.amount - p.gst}}</span>
                </div>
                <div class="detail-row">
                    <span>GST and Tax</span>
                    <span><i class="fa fa-inr" aria-hidden="true"></i>

{{p.gst}}</span>
                </div>
                <div class="detail-row total">
                    <span>Total payable amount</span>
                    <span class="black"><i class="fa fa-inr" aria-hidden="true"></i>

{{p.amount}}</span>
                </div>
            </div>
            <p class="auto-renew">Your subscription will auto renew until you cancel</p>
            <button class="btn-proceed cursor" (click)="proccedToPay(p.amount,p._id)">Proceed to Pay <i class="fa fa-inr" aria-hidden="true"></i>
{{p.amount}}</button>
            <a  class="view-plans cursor" (click)="otherPlans()">Other Subscription Plans</a>
        </div>
    </div>
    </div> 

      <div class="container p-s" *ngIf="success"> 
        <img src="../../../../assets/images/check.png" width="70px" class="s-img1">
        <h1>Payment Successful for <span class="location-name">{{locationName}}</span></h1>
        <div class="amount"><i class="fa fa-inr" aria-hidden="true"></i>
{{totalmount}}</div>
        <p class="recipient">Paid to</p>
        <p class="recipient">Localfirst software solutions private limited</p>
        <div class="transaction-id">
            Transaction ID: <span>{{razorpay_payment_id}}</span>
        </div>
        <!-- <p class="date-time">11:00 AM, 20th May 2022</p> -->
        <p class="message">Now you can continue to request reviews and referrals from our Localfirst app</p>
        <p class="message1">Login to the LocalFirst app now</p>
        <button class="btn-open-app">Open the app</button>
        <a  class="manage-subscription cursor" (click)="manage()">Manage subscription</a>
        <div class="help">
            <a  class="need-help">Need Help</a>
        </div>
    </div>
      <div class="container p-s" *ngIf="failed"> 
        <img src="../../../../assets/images/check.png" width="70px" class="s-img1">
        <h1>Payment Failed for <span class="location-name">{{locationName}}</span></h1>
        <div class="amount"><i class="fa fa-inr" aria-hidden="true"></i>
{{totalmount}}</div>
    
        <!-- <p class="date-time">11:00 AM, 20th May 2022</p> -->
        <p class="message">Now you can continue to request reviews and referrals from our Localfirst app</p>
        <p class="message1">Login to the LocalFirst app now</p>
        <button class="btn-open-app">Open the app</button>
        <a  class="manage-subscription cursor" (click)="manage()">Manage subscription</a>
        <div class="help">
            <a  class="need-help">Need Help</a>
        </div>
    </div>