import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-reqreviewmoblie',
  templateUrl: './reqreviewmoblie.component.html',
  styleUrls: ['./reqreviewmoblie.component.css'],
})
export class ReqreviewmoblieComponent implements OnInit {
  reviews: FormGroup;
  Submitted: any;
  selectedOption: string = 'googleReview';
  localRefferal: boolean = false;
  googleReview: boolean = false;
  location_id: any;
  modal: boolean = false;
  value: any;
  success: boolean = false;
  instagram: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: ServicesService
  ) {
    this.reviews = this.fb.group({
      number: ['', [Validators.required]],
      name: ['', [Validators.required]],
      amount: ['', [Validators.required]],
    });
  }
  ngOnInit() {
    this.location_id = localStorage.getItem('locationId');
  }
  request(e: any) {
    console.log(e.value);
    this.value = e.value;
    if (e.value == 'Google') {
      this.googleReview = true;
      this.localRefferal = false;
      this.instagram = false;
    } else if (e.value == 'Referral') {
      this.googleReview = false;
      this.instagram = false;

      this.localRefferal = true;
    } else if (e.value == 'Instagram') {
      this.googleReview = false;
      this.instagram = true;

      this.localRefferal = false;
    }
  }
  continue() {
    if (this.reviews.invalid) {
      this.Submitted = true;
    } else {
      this.modal = true;
    }
  }
  get reviewsA(): { [key: string]: AbstractControl } {
    return this.reviews.controls;
  }
  isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }
  requestReview() {
    let obj = {
      mobileNumber: this.reviews.value.number,
      customerName: this.reviews.value.name,
      billingAmount: this.reviews.value.amount,
      locationId: this.location_id,
      sendReferralRequest: this.localRefferal,
      requestGoogleReview: this.googleReview,
      requestInstagramFollow: this.instagram,
    };
    this.service.createReviews(obj).subscribe((response) => {
      console.log(response);
      this.success = true;
    });
  }
  goToHome() {
    this.router.navigate(['/Reviews']);
  }
}
