import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-homemoblie',
  templateUrl: './homemoblie.component.html',
  styleUrls: ['./homemoblie.component.css'],
})
export class HomemoblieComponent implements OnInit {
  location_id: any;
  referralActivityCount: any;
  reviewDataCount: any;
  highcharts = Highcharts;
  refferalchart: any;
  customerVisitChart: any;
  reviewschart: any;
  reviewsData: any = ['RequestSent', 'Clicks'];
  data: any = [
    'RequestSent',
    'ReferralClicks',
    'LeadsCount',
    'LeadsFirstCount',
  ];
  locationName: any;
  dateFliter: any = 'thisWeek';
  customerVisitCount: any;
  customerData: any = ['First Time', 'Repeat'];
  LocationDetails: any;
  locationsDetailsComplete: any;
  constructor(private service: ServicesService, private router: Router) {}

  ngOnInit(): void {
    this.apiCall();
  }
  changeFilter(e: any) {
    this.dateFliter = e.target.value;
    this.apiCall();
  }
  redirectToRequestReview() {
    this.router.navigate(['/Reviews-request']);
  }
  locationChage(e: any) {
    console.log(e.value);

    this.locationsDetailsComplete = this.LocationDetails.filter((res: any) => {
      return res._id == e.value;
    });
    console.log(this.locationsDetailsComplete);
    this.locationName = localStorage.setItem(
      'locationName',
      this.locationsDetailsComplete[0].name
    );
    this.location_id = localStorage.setItem(
      'locationId',
      this.locationsDetailsComplete[0]._id
    );
    this.apiCall();
  }
  apiCall() {
    this.locationName = localStorage.getItem('locationName');
    this.location_id = localStorage.getItem('locationId');
    this.service.merchantDetails().subscribe((res) => {
      this.LocationDetails = res.logInData.locations;
      console.log(this.LocationDetails);
    });
    let obj = {
      locationId: this.location_id,
      duration: this.dateFliter,
    };
    this.service.homeDashboard(obj).subscribe((result) => {
      // console.log(result);
      this.referralActivityCount = result.referralActivity;
      this.reviewDataCount = result.reviewData;
      this.customerVisitCount = result.customerVisit;
      console.log(this.referralActivityCount);
      this.refferalchart = {
        chart: {
          type: 'column',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: 'Referral',
        },
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        autoRotation: false,
        xAxis: {
          type: 'categories',
          categories: this.data,
        },

        legend: {
          enabled: false,
          reversed: false,
        },
        showInLegend: false,

        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.2, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y}',
            },
          },
        },
        colors: ['#8b8d6966'],

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },

        series: [
          {
            // name: this.data,
            // data: this.count,
            data: [
              {
                name: 'requestSent',
                y: this.referralActivityCount.requestSent,
                drilldown: 'requestSent',
              },
              {
                name: 'referralClicks',
                y: this.referralActivityCount.referralClicks,
                drilldown: 'referralClicks',
              },
              {
                name: 'leadsCount',
                y: this.referralActivityCount.leadsCount,
                drilldown: 'leadsCount',
              },
              {
                name: 'leadsFirstCount',
                y: this.referralActivityCount.leadsFirstCount,
                drilldown: 'leadsFirstCount',
              },
            ],
          },
        ],
      };
      this.reviewschart = {
        chart: {
          type: 'column',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: 'Reviews',
        },
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        autoRotation: false,
        xAxis: {
          type: 'categories',
          categories: this.reviewsData,
        },

        legend: {
          enabled: false,
          reversed: false,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.2, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y}',
            },
          },
        },
        colors: ['#8b8d6966'],

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },

        series: [
          {
            // name: this.data,
            // data: this.count,
            data: [
              {
                name: 'requestSent',
                y: this.reviewDataCount.requestSent,
                drilldown: 'requestSent',
              },
              {
                name: 'clicked',
                y: this.reviewDataCount.clicked,
                drilldown: 'clicked',
              },
            ],
          },
        ],
      };
      this.customerVisitChart = {
        chart: {
          type: 'column',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: 'Customers',
        },
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        autoRotation: false,
        xAxis: {
          type: 'categories',
          categories: this.customerData,
        },

        legend: {
          enabled: false,
          reversed: false,
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Adjust these values
            groupPadding: 0.2, // to control the bar height
            borderWidth: 0, // Optional: remove border to visually increase height
            dataLabels: {
              enabled: true,
              format: '{point.y}',
            },
          },
        },
        colors: ['#8b8d6966'],

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },

        series: [
          {
            // name: this.data,
            // data: this.count,
            data: [
              {
                name: 'first Time',
                y: this.customerVisitCount.nonRepeatCustomers,
                drilldown: 'first Time',
              },
              {
                name: 'repeatCustomers',
                y: this.customerVisitCount.repeatCustomers,
                drilldown: 'repeatCustomers',
              },
            ],
          },
        ],
      };
    });
  }
}
