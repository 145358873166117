
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>


    <div class="app-wrapper">
        <div class="top-nav">
            <h1 id="current-tab">Home</h1>
            <div class="location-dropdown" id="dropdown-button" >
                <!-- <span id="location-name" class="truncate">{{locationName}}</span>
               <img src="../../../../assets/images/arrow-down.svg" alt="down-arrow">
                <ul id="location-list" class="dropdown hidden"></ul> -->

                <select (change)="locationChage($event.target)">
                    <option>{{locationName}}</option>
                    <option *ngFor="let loc of LocationDetails" [value]="loc._id" >{{loc.name}}</option>
                </select>
            </div>
        </div>

        <!-- Home Section (Visible only when Home is selected) -->
        <div class="content-wrapper home-content">
              <div class="filters text-right pr-3">
            <select class="filter-dropdown" (change)="changeFilter($event)">
                <option value="thisWeek">This week</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This year</option>
            </select>
            </div>
            <div class="home-content">
              

                 <div class="card activity">
                      <div class="">
                    <h2>Reviews Activity <span class="orange"></span></h2>
                    <p>You sent <a href="#">{{reviewDataCount?.requestSent}} review requests</a> in the {{dateFliter.trim(',')}}.</p>
                    <!-- <p class="prompt">Send your first review request</p> -->
                </div>
                   <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="reviewschart" 
             style="width:300px; height: 300px;display:block"></highcharts-chart>
                    <div class="activity-grid">
                        <div>
                            <img src="../../../../assets/images/send.svg" alt="send Icon">
                            <span class="count">{{reviewDataCount?.requestSent}}</span>
                            <span class="label">Request sent</span>
                        </div>
                        <div>
                            <img src="../../../../assets/images/clicks.svg" alt="send Icon">
                            <span class="count">{{reviewDataCount?.clicked}}</span>
                            <span class="label">Clicks</span>
                        </div>
                      
                      
                    </div>
                </div>

              

                <div class="card activity">
                         <div class="">
                    <h2>Referrals Activity <span class="orange"></span></h2>
                    <p>You sent <a href="#">{{referralActivityCount?.requestSent}} referral requests</a> in the {{dateFliter.trim(',')}}.</p>
                    <!-- <p class="prompt">Send your first review request</p> -->
                </div>
                     <div class="chart-customer">
            
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="refferalchart" 
             style="width:300px; height: 300px;display:block"></highcharts-chart>
      </div>
                    <div class="activity-grid">
                        <div>
                            <img src="../../../../assets/images/send.svg" alt="send Icon">
                            <span class="count">{{this.referralActivityCount?.requestSent}}</span>
                            <span class="label">Request sent</span>
                        </div>
                        <div>
                            <img src="../../../../assets/images/clicks.svg" alt="send Icon">
                            <span class="count">{{this.referralActivityCount?.referralClicks}}</span>
                            <span class="label">Clicks</span>
                        </div>
                        <div>
                            <img src="../../../../assets/images/leads.svg" alt="send Icon">
                            <span class="count">{{this.referralActivityCount?.leadsCount}}</span>
                            <span class="label">Leads</span>
                        </div>
                        <div>
                           <img src="../../../../assets/images/firstvisits.svg" alt="send Icon">
                            <span class="count">{{this.referralActivityCount?.leadsFirstCount}}</span>
                            <span class="label">First Visit</span>
                        </div>
                    </div>
                </div>
                 

                <div class="card activity oneA">
                     <div class="">
                    <h2>Customers Visits <span class="orange"></span></h2>
                    <p>{{this.customerVisitCount.totalCustomers}} customers have visited your business {{dateFliter.trim(',')}}.</p>
                    <!-- <p class="prompt">Send your first review request</p> -->
                </div>
                     <div class="chart-customer">
            
<highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="customerVisitChart" 
             style="width:300px; height: 300px;display:block"></highcharts-chart>
      </div>
                    <div class="activity-grid">
                        <div>
                            <img src="../../../../assets/images/firstvisits.svg" alt="send Icon">
                            <span class="count">{{this.customerVisitCount.nonRepeatCustomers}}</span>
                            <span class="label">First time</span>
                        </div>
                        <div>
                            <img src="../../../../assets/images/firstvisits.svg" alt="send Icon">
                            <span class="count">{{this.customerVisitCount.nonRepeatCustomers}}</span>
                            <span class="label">Repeat</span>
                        </div>
                        <div>
                            <img src="../../../../assets/images/firstvisits.svg" alt="send Icon">
                            <span class="count">{{this.customerVisitCount.totalCustomers}}</span>
                            <span class="label">Total customers</span>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

        <!-- Other Section Content (Placeholder for Reviews, Referrals, etc.) -->
        <div class="content-wrapper other-content" style="display: none;">
            <!-- Empty for now, will be updated later -->
        </div>

        <!-- Floating Request Review Button -->
       <!-- Floating Request Review Button in home.html -->
<button class="request-btn"(click)="redirectToRequestReview()"> 
    <img src="../../../../assets/images/magic-star.png" alt="Star Icon" class="button-icon">
    Request Review
</button>

 
 
 
    </div>