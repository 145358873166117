
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>

  <div class="app-wrapper">
        <!-- Top Navigation -->

           <div class="top-nav">
            <div class="nav-left">Customers</div>
            <div class="nav-right">
                 <div class="location-dropdown" id="dropdown-button" >
                <!-- <span id="location-name" class="truncate">{{locationName}}</span>
               <img src="../../../../assets/images/arrow-down.svg" alt="down-arrow">
                <ul id="location-list" class="dropdown hidden"></ul> -->

                <select (change)="locationChage($event.target)">
                    <option>{{locationName}}</option>
                    <option *ngFor="let loc of LocationDetails" [value]="loc._id" >{{loc.name}}</option>
                </select>
            </div>
            </div>
        </div>

        <!-- Review Filters -->
        <div class="filters">
               <select class="filter-dropdown" (change)="changeFilter($event)" [(ngModel)]="dateFliter">
                <option value="thisWeek">This week</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This year</option>
            </select>
             
            <button class="help-button">Help</button>
        </div>

<!-- 
        <div class="top-nav">
            <div class="nav-left">Customers</div>
            <div class="nav-right">
                <div>
                 <select class="filter-dropdown" (change)="changeFilter($event)">
                <option value="thisWeek">This week</option>
                <option value="thisMonth">This month</option>
                <option value="thisYear">This year</option>
            </select>
                </div>
                <div class="help-button">Help</div>
            </div>
        </div> -->

        <!-- Content Section -->
        <div class="content">
            <!-- Total Customers -->
            <h2>You have a total of {{reviewDataCount?.totalCustomers}} customers</h2>

            <!-- Pie Chart Section -->
            <div class="pie-chart-section">
          <highcharts-chart 
             [Highcharts]="highcharts" 
             [options]="reviewschart" 
             style="width:250px; height: 200px;display:block"></highcharts-chart>
               
            </div>
 <div class="pie-chart-legend d-flex justify-content-between">
                    <p><span class="dot"></span>Repeat Customers<br><span>{{reviewDataCount?.repeatCustomers}} ({{ repeatCustomerPercentage?.toFixed(2) }}%)</span></p>
                    <p><span class="dot1"></span>Not Repeat Customers<br><span>{{reviewDataCount?.nonRepeatCustomers}} ({{ nonRepeatCustomerPercentage?.toFixed(2) }}%)</span></p>
                </div>
           <div class="visit-summary">
    <h3>Visits Summary ({{reviewDataCount?.totalCustomers}})</h3>
    <p>{{reviewDataCount?.repeatCustomers}} new and {{reviewDataCount?.nonRepeatCustomers}} repeat customers visited this week</p>

    <div class="visit-bars">
        

<div class="chart-container">
  <div class="chart-item" *ngFor="let key of visitKeys()">
    <span>{{key}}</span>
    <mat-progress-bar color="primary" [value]="getPercentage(visitSummary[key])" max="200" mode="determinate"></mat-progress-bar>
    <span class="value">{{visitSummary[key]}}</span>
  </div>
  </div>
<!-- <p>{{key}}%</p> -->
 
    </div>
</div>

            <!-- Customer Visits List -->
            <div class="customer-visits">
                <input type="text" placeholder="Search customer by phone number" (input)="search($event)">
                <div class="customer-card" *ngFor="let cu of customers">

                    <div class="d-flex">
                        <div class="d-flex">
                            <div>
                                <img src="../../../../assets/images/proflie.png" class="p-img">
                            </div>
                            <div>
                   <p>{{cu.customerName}}</p>
                    <h6>{{cu.mobileNumber}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                    <p>Bill Amount: </p>
                       <p>{{cu.totalBillingAmount}} INR</p>
                    </div>
                    <div class="d-flex justify-content-between">
                    <p>Visited: </p>
                       <p>{{cu.lastVisited}} </p>
                    </div>
                     
                    <div class="d-flex justify-content-between">
                    <p>Review Request: </p>
                       <p *ngIf="cu.reviewRequested == 'Sent'">Review Submitted</p>
                       <p *ngIf="cu.reviewRequested == 'Not yet sent'">Review not Submitted</p>
                    </div>
                    <div class="d-flex justify-content-between">
                    <p>Referral Request: </p>
                       <p *ngIf="cu.referralRequest == 'Sent'">Referred</p>
                       <p *ngIf="cu.referralRequest == 'Not yet sent'">Not Referred</p>
                       
                    </div>
                    <div class="visit-count">{{cu.customerVisitCount}} Visits</div>
                </div>
                <!-- Add more customer cards similarly -->

              
            </div>
        </div>

        <button class="request-btn" (click)="redirectToRequestReview()"> 
    <img src="../../../../assets/images/magic-star.png" alt="Star Icon" class="button-icon">
    Request Review
</button>
 

    </div>