import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  otpVerifed: any;
  otp: any;
  minutes: any;
  seconds: any;
  interval: any;
  timer: any;
  err: any;
  Otperr: any;
  select: boolean = false;
  locations: any;
  locationName: any;
  formatted_address: any;
  error1: any;
  login1Loader: boolean = false;
  locationId: any;
  locationCard: any;
  selectedBussiness: any = '';
  errorMessage: boolean = false;
  resendBtn() {
    throw new Error('Method not implemented.');
  }
  error: any;
  resend: any;

  number: any = '';
  // next: any;
  verify: boolean = false;
  loginScreen: boolean = false;
  login1: boolean = true;
  constructor(private router: Router, private service: ServicesService) {}

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.router.navigate(['/merchants/freetrail']);
    }
  }

  manageAccount() {
    this.login1 = false;
    this.loginScreen = true;
  }
  changeNumber() {
    this.loginScreen = true;
    this.verify = false;
  }
  next() {
    if (this.number == '') {
      this.err = 'Phone number is required';
    } else {
      this.login1Loader = true;
      let obj = {
        mobileNumber: this.number,
      };
      this.service.Login(obj).subscribe(
        (res) => {
          this.login1Loader = false;

          this.loginScreen = false;
          this.verify = true;
        },
        (err) => {
          this.err = err.error.error;
          console.log(err);
        }
      );
    }

    // this.loginScreen = false;
    // this.verify = true;
  }
  nextOtp() {
    if (this.otp == '') {
      this.error = 'otp is required';
    } else {
      this.login1Loader = true;
      let obj = {
        mobileNumber: this.number,
        otp: this.otp,
      };
      this.service.verifyOtp(obj).subscribe(
        (res) => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('merchnatId', res.merchnatId);
          localStorage.setItem('merchantName', res.merchantName);
          localStorage.setItem('email', res.email);
          localStorage.setItem('mobileNumber', res.mobileNumber);

          this.service.merchantDetails().subscribe((res) => {
            this.login1Loader = false;

            this.verify = false;
            this.select = true;
            this.locationCard = res.logInData.locations;
            // this.locationName = res.logInData.locations[0].name;
            // this.locationId = res.logInData.locations[0]._id;

            this.formatted_address =
              res.logInData.locations[0].formatted_address;
          });
        },
        (err) => {
          this.error = err.error.message;
        }
      );
    }
  }
  selectCard(card: any) {
    // Mark all cards as not selected
    this.locationCard.forEach((c: any) => (c.selected = false));
    // Mark the clicked card as selected
    card.selected = true;
    this.selectedBussiness = card;
    console.log(this.selectedBussiness);
    if (this.selectedBussiness != '') {
      this.errorMessage = false;
    }
  }
  manage() {
    if (this.selectedBussiness) {
      this.locationName = this.selectedBussiness.name;
      this.locationId = this.selectedBussiness._id;
      localStorage.setItem('locationName', this.locationName);
      localStorage.setItem('locationId', this.locationId);
      this.router.navigate(['/Home']);
    } else {
      this.errorMessage = true;
    }
  }
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 4,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  startTimer(): void {
    this.minutes = 1; // Set initial minutes
    this.seconds = 0; // Set initial seconds

    this.interval = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          clearInterval(this.interval); // Stop the timer when it reaches 0
          this.resend = false;
          this.timer = false;
        } else {
          this.minutes--; // Decrement minutes when seconds reach 0
          this.seconds = 59; // Reset seconds to 59
        }
      } else {
        this.seconds--; // Decrement seconds
      }
    }, 1000); // Update every second
  }
  handeOtpChange(value: string[]): void {
    console.log(value);
    this.otp = value.toString().replace(/,/g, '');
  }

  handleFillEvent(value: string): void {
    console.log(value.length);
    if (value.length == 6) {
      this.otpVerifed = true;
    }
  }
  isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  validate(e: any) {
    this.number = e.target.value;
    if (this.number.length == 10) {
      this.err = '';
      // this.next = false;
    } else {
      // this.next = true;
    }
  }
}
