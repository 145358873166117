 <div class="bottom-nav">
            <div class="nav-item" (click)="route('Home')" >
                  <img src="../../../../assets/images/home.svg">
                <span  [class.active-route]="routeNavigate === 'Home'"
     [class.inactive-route]="routeNavigate !== 'Home'">Home</span>
            </div>

            <div class="nav-item"  (click)="route('Reviews')">
               <img src="../../../../assets/images/reviews.svg">
                <span [class.active-route]="routeNavigate === 'Reviews'"
     [class.inactive-route]="routeNavigate !== 'Reviews'">Reviews</span>
            </div>
                <div class="nav-item" (click)="route('Refferals')">
                 <img src="../../../../assets/images/referrals.svg">
                <span [class.active-route]="routeNavigate === 'Refferals'"
     [class.inactive-route]="routeNavigate !== 'Refferals'">Referrals</span>
            </div>

            <div class="nav-item" (click)="route('Customers')">
                 <img src="../../../../assets/images/customers.svg">
                <span [class.active-route]="routeNavigate === 'Customers'"
     [class.inactive-route]="routeNavigate !== 'Customers'">Customers</span>
            </div>
            <div class="nav-item" (click)="route('subscription')">
                 <img src="../../../../assets/images/premium.svg">
                <span [class.active-route]="routeNavigate === 'subscription'"
     [class.inactive-route]="routeNavigate !== 'subscription'">Premium</span>
            </div>
        </div>