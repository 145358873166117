import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-customerssmoblie',
  templateUrl: './customerssmoblie.component.html',
  styleUrls: ['./customerssmoblie.component.css'],
})
export class CustomerssmoblieComponent implements OnInit {
  locationName: any;
  location_id: any | null;
  rating: any;
  reviewCount: any;
  highcharts = Highcharts;
  refferalchart: any;
  customerVisitChart: any;
  reviewschart: any;
  reviewsData: any = ['RequestSent', 'Clicks'];
  reviewDataCount: any;
  dateFliter: any = 'thisYear';
  reviews: any;
  customers: any;
  number: any = '';
  visitSummary: any;
  nonRepeatCustomerPercentage: any;
  repeatCustomerPercentage: any;
  locationsDetailsComplete: any;
  LocationDetails: any;
  constructor(private service: ServicesService, private router: Router) {}

  ngOnInit(): void {
    this.apiCall();
  }
  locationChage(e: any) {
    console.log(e.value);

    this.locationsDetailsComplete = this.LocationDetails.filter((res: any) => {
      return res._id == e.value;
    });
    console.log(this.locationsDetailsComplete);
    this.locationName = localStorage.setItem(
      'locationName',
      this.locationsDetailsComplete[0].name
    );
    this.location_id = localStorage.setItem(
      'locationId',
      this.locationsDetailsComplete[0]._id
    );
    this.apiCall();
  }
  apiCall() {
    this.locationName = localStorage.getItem('locationName');
    this.location_id = localStorage.getItem('locationId');
    this.service.merchantDetails().subscribe((result: any) => {
      console.log(result);
      this.LocationDetails = result.logInData.locations;
      this.locationsDetailsComplete = this.LocationDetails.filter(
        (res: any) => {
          return res._id == this.location_id;
        }
      );
      console.log(this.locationsDetailsComplete);

      this.rating = this.locationsDetailsComplete[0].rating;
      this.reviews = this.locationsDetailsComplete[0].reviews;
      this.reviewCount = this.locationsDetailsComplete[0].user_ratings_total;
    });
    let obj = {
      locationId: this.location_id,
      duration: this.dateFliter,
      mobileNumber: this.number,
    };
    this.service.customeDashboard(obj).subscribe((result) => {
      // this.service.reviewsDashboard(obj).subscribe((result) => {
      console.log(result);
      // this.referralActivityCount = result.referralActivity;
      this.reviewDataCount = result;
      this.customers = result.customers;
      this.visitSummary = result.visitSummary;
      this.calculateCustomerPercentages();
      // console.log(this.referralActivityCount);
      this.reviewschart = {
        chart: {
          type: 'pie',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        title: {
          text: 'Customers',
        },
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'none',
        },
        autoRotation: false,
        xAxis: {
          type: 'categories',
          // categories: this.reviewsData,
        },

        legend: {
          enabled: false,
          reversed: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            colorByPoint: true,
            type: 'pie',
            size: '100%',
            innerSize: '80%',
            dataLabels: {
              enabled: true,
              crop: false,
              distance: '-10%',
              style: {
                fontWeight: 'bold',
                fontSize: '10px',
              },
              connectorWidth: 0,
            },
          },
        },
        // plotOptions: {
        //   series: {
        //     pointPadding: 0, // Adjust these values
        //     groupPadding: 0.2, // to control the bar height
        //     borderWidth: 0, // Optional: remove border to visually increase height
        //     dataLabels: {
        //       enabled: true,
        //       format: '{point.y}',
        //     },
        //   },
        // },
        colors: ['#8b8d6966', '#068466'],

        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>',
        },

        series: [
          {
            // name: this.data,
            // data: this.count,
            data: [
              {
                name: 'repeatCustomers',
                y: this.reviewDataCount.repeatCustomers,
                drilldown: 'repeatCustomers',
              },
              {
                name: 'nonRepeatCustomers',
                y: this.reviewDataCount.nonRepeatCustomers,
                drilldown: 'nonRepeatCustomers',
              },
            ],
          },
        ],
      };
    });
  }
  visitKeys(): string[] {
    return Object.keys(this.visitSummary);
  }
  changeFilter(e: any) {
    this.dateFliter = e.target.value;
    this.apiCall();
  }
  search(e: any) {
    this.number = e.target.value;
    let obj = {
      locationId: this.location_id,
      duration: this.dateFliter,
      mobileNumber: this.number,
    };
    this.service.customeDashboard(obj).subscribe((result) => {
      // this.service.reviewsDashboard(obj).subscribe((result) => {
      console.log(result);
      // this.referralActivityCount = result.referralActivity;
      // this.reviewDataCount = result;
      this.customers = result.customers;
    });
  }
  maxValue = 200;

  redirectToRequestReview() {
    this.router.navigate(['/Reviews-request']);
  }
  getPercentage(value: number): number {
    return (value / this.maxValue) * 100;
  }
  calculateCustomerPercentages(): void {
    if (this.reviewDataCount?.totalCustomers > 0) {
      this.repeatCustomerPercentage =
        (this.reviewDataCount?.repeatCustomers /
          this.reviewDataCount?.totalCustomers) *
        100;
      this.nonRepeatCustomerPercentage =
        (this.reviewDataCount?.nonRepeatCustomers /
          this.reviewDataCount?.totalCustomers) *
        100;
    }
  }
}
