import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insta-follower',
  templateUrl: './insta-follower.component.html',
  styleUrls: ['./insta-follower.component.css']
})
export class InstaFollowerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
