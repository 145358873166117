import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { EarnwithAutomatikComponent } from './components/earnwith-automatik/earnwith-automatik.component';
import { CommonbussiesstypeComponent } from './components/commonbussiesstype/commonbussiesstype.component';
import { TeammangementComponent } from './components/teammangement/teammangement.component';
import { ReferralsComponent } from './components/referrals/referrals.component';
import { SmsmarketingComponent } from './components/smsmarketing/smsmarketing.component';
import { AutopliotComponent } from './components/autopliot/autopliot.component';
import { TermsofserviceComponent } from './components/termsofservice/termsofservice.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { CancellationpolicyComponent } from './components/cancellationpolicy/cancellationpolicy.component';
import { RefundpolicyComponent } from './components/refundpolicy/refundpolicy.component';
import { LoginComponent } from './components/login/login.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { FreetrailrunningComponent } from './components/subscription/freetrailrunning/freetrailrunning.component';
import { SubscriptionexpiredComponent } from './components/subscription/subscriptionexpired/subscriptionexpired.component';
import { SubscriptioncancelledComponent } from './components/subscription/subscriptioncancelled/subscriptioncancelled.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './_services/token.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { MoblienavbarComponent } from './components/webview/moblienavbar/moblienavbar.component';
import { ReviewsmoblieComponent } from './components/webview/reviewsmoblie/reviewsmoblie.component';
import { RefferalsmoblieComponent } from './components/webview/refferalsmoblie/refferalsmoblie.component';
import { CustomerssmoblieComponent } from './components/webview/customerssmoblie/customerssmoblie.component';
import { HomemoblieComponent } from './components/webview/homemoblie/homemoblie.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { StarRatingModule } from 'angular-star-rating';
import { ReqreviewmoblieComponent } from './components/webview/reqreviewmoblie/reqreviewmoblie.component';
import { InstaFollowerComponent } from './components/insta-follower/insta-follower.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ReviewsComponent,
    FooterComponent,
    HomeComponent,
    PricingComponent,
    EarnwithAutomatikComponent,
    CommonbussiesstypeComponent,
    TeammangementComponent,
    ReferralsComponent,
    SmsmarketingComponent,
    AutopliotComponent,
    TermsofserviceComponent,
    PrivacypolicyComponent,
    AboutusComponent,
    ContactusComponent,
    CancellationpolicyComponent,
    RefundpolicyComponent,
    LoginComponent,
    FreetrailrunningComponent,
    SubscriptionexpiredComponent,
    SubscriptioncancelledComponent,
    LoaderComponent,
    MoblienavbarComponent,
    ReviewsmoblieComponent,
    RefferalsmoblieComponent,
    CustomerssmoblieComponent,
    HomemoblieComponent,
    ReqreviewmoblieComponent,
    InstaFollowerComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxOtpInputModule,
    HighchartsChartModule,
    StarRatingModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
