 
 
<app-navbar></app-navbar>
<app-moblienavbar></app-moblienavbar>
 <div class="request-form">
    <form [formGroup]="reviews">

            <label for="phone-number mb-3">Mobile Number</label>
            <input  pattern="\d*"  inputmode="numeric" (keypress)="isNumberKey($event)" [maxLength]="10" id="phone-number" formControlName="number" placeholder="Enter Customer phone number">
   <div *ngIf="
                                  reviewsA.number.errors
                                    ?.required && Submitted
                                " class="invalid-feedback d-block">
                                Please Enter Customer Number 
                              </div>
            <label for="customer-name mb-3">Customer Name</label>
            <input type="text" id="customer-name" formControlName="name" placeholder="Enter Customer Name">
   <div *ngIf="
                                  reviewsA.number.errors
                                    ?.required && Submitted
                                " class="invalid-feedback d-block">
                                Please Enter Customer Name 
                              </div>
            <label for="billing-amount mb-3">Billing Amount</label>
            <input type="text" pattern="\d*"  inputmode="numeric" (keypress)="isNumberKey($event)" [maxLength]="10"  id="billing-amount" formControlName="amount" placeholder="Eg.. ₹2,000">
               <div *ngIf="
                                  reviewsA.number.errors
                                    ?.required && Submitted
                                " class="invalid-feedback d-block">
                                Please Enter Billing amount 
                              </div>
    </form>

            <button class="continue-btn" (click)="continue()">Continue</button>
        </div>


           <div class="container" *ngIf="modal">
        <div class="trail-model">
            <div >
              <img src="../../../../assets/images/cancel.png" class="close3">
            </div>
        <div class="header">
            <div class="indicator"></div>
        </div>
       <div class="pt-4">
          <div class="poli-new">
              <p>{{this.reviews.value.name}}{{this.reviews.value.number}} is a new customer.You can send request for review politely</p>
          </div>

          

<div class="card-container">
  <mat-radio-group [(ngModel)]="selectedOption" (change)="request($event)">
    <label class="option-container">
      <div class="option-content">
                   <img src="../../../../assets/images/goo.svg" class="mr-2">

        <div class="option-text">
          <h3>Request Google Review</h3>
          <p>Get google review in minutes</p>
        </div>
      </div>
      <mat-radio-button class="custom-radio-button custom-checkbox" value="Google"></mat-radio-button>
    </label>

    <label class="option-container">
      <div class="option-content">
                     <img src="../../../../assets/images/goo1.svg" class="mr-2">

        <div class="option-text">
          <h3>Send Local Referrals Request</h3>
          <p>Get more customers via word of mouth</p>
        </div>
      </div>
      <mat-radio-button class="custom-radio-button" value="Referral"></mat-radio-button>
    </label>
    <label class="option-container">
      <div class="option-content">
                     <img src="../../../../assets/images/instagram (1).svg" class="i-img1">

        <div class="option-text">
          <h3>Request Instagram Follow</h3>
          <p>Unquie way of get followers</p>
        </div>
      </div>
      <mat-radio-button class="custom-radio-button" value="Instagram"></mat-radio-button>
    </label>
  </mat-radio-group>

  <button mat-raised-button color="primary" class="action-button" (click)="requestReview()">
    Send {{value}} Request
  </button>
</div>

         
          <div class="d-flex">

          </div>
       </div>
        </div>
    </div>


    <div *ngIf="success">
        <div class="success-container">
  <div class="success-message">
    <img src="../../../../assets/images/successfully-done 1.svg">
    <h2>{{value}} Request Sent Successfully!</h2>
    <p>An SMS with {{value}} request is sent.</p>
  </div>

  <div class="tip-message">
    <p>Tip: Request the customer to provide the {{value}}, politely.</p>
  </div>

  <button class="submit-button" (click)="goToHome()">Submit & Go to home</button>
</div>

    </div>